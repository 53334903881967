import { PermissionName } from '~/graphql/graphql';
import type { RouteRecordRaw } from 'vue-router';

const exactRoutes: RouteRecordRaw[] = [
    {
        name: 'exact.overview',
        path: '/exact',
        component: async () => import('~/views/exact/Overview.vue'),
        meta: {
            middleware: {
                before: ['auth', 'can'],
            },
            can: [PermissionName.ManageExact],
        },
    },
];

export default exactRoutes;
