import toasts from './toasts';
import components from './components';
import views from './views';
import forms from './forms';
import general from './general';
import notifications from './notifications';
import enums from './enums';

export default {
    ...general,
    ...forms,
    ...components,
    ...notifications,
    ...enums,
    ...toasts,
    ...views,
    'Medical information': 'Medische informatie',
    'Enter the patient\'s medical information here.': 'Vul hier de medische gegevens van de patient in.',
    Dismiss: 'Sluiten',
    'Server error': 'Server fout',
    'There was an unidentified error on the server.': 'Er is een onbekende fout op de server opgetreden',
    'There was an error updating your availability.': 'Je beschikbaarheid kon niet worden doorgegeven.',
    'Activate your account': 'Activeer je account',
    'Personal details': 'Persoonsgegevens',
    'First name': 'Voornaam',
    'Last name': 'Achternaam',
    'Birth date': 'Geboortedatum',
    'Phone number': 'Telefoonnummer',
    'Mobile number': 'Mobiele telefoonnummer',
    'You can only submit your availability up to two days in advance. Do you wish to change your availability, but is this no longer possible? Please contact Stichting Ambulance Wens via': 'Je '
        + 'kunt je beschikbaarheid tot uiterlijk 2 dagen van tevoren doorgeven. Wil je jouw beschikbaarheid wijzigen, '
        + 'maar is dit niet meer mogelijk? Neem dan telefonisch contact op met Stichting Ambulance Wens via',
    'Travel allowance': 'Kilometervergoeding',
    Address: 'Adres',
    'Address details': 'Adresgegevens',
    'Add address': 'Adres toevoegen',
    'Remove address': 'Adres verwijderen',
    'Are you sure you want to delete this address?': 'Weet je zeker dat je dit adres wil verwijderen?',
    'Residential address': 'Woonadres',
    'Additional address': 'Extra adres',
    Street: 'Straat',
    Number: 'Nummer',
    Addition: 'Toevoeging',
    'Postal code': 'Postcode',
    City: 'Plaats',
    'Bank information': 'Bankgegevens',
    'Account holder': 'Rekeninghouder',
    'Account number': 'IBAN',
    'Emergency contact': 'Contactpersoon in geval van nood',
    'BIG number': 'BIG nummer',
    'Reserved procedures': 'Voorbehouden handelingen',
    Name: 'Naam',
    Save: 'Opslaan',
    'Edit': 'Bewerken',
    'Your profile': 'Uw profiel',
    'Confirm your personal data': 'Bevestig je persoonsgegevens',
    'Check that the correct personal information is known to us. Complete with your phone number so we can contact you by phone.': 'Controleer of de juiste persoonsgegevens bij ons bekend zijn. Vul aan met je telefoonnummer, zodat we telefonisch contact met je kunnen opnemen.',
    'Please provide your address information': 'Geef je adresgegevens door',
    'We need this information to ensure that we can calculate any mileage charges. Please enter your address below.': 'We hebben deze gegevens nodig om ervoor te zorgen dat we eventuele kilometervergoeding kunnen berekenen. Vul hieronder je adres in.',
    'Please provide your bank details': 'Geef je bankgegevens door',
    'In order for us to reimburse your incurred expenses, we need your bank details. Please enter your bank details below so we can securely process your payments': 'Om ervoor te zorgen dat we je gemaakte onkosten kunnen vergoeden, hebben we je bankgegevens nodig. Vul hieronder je bankgegevens in, zodat we je betalingen veilig kunnen verwerken',
    'Who can we call in case of an emergency?' : 'Wie kunnen we bellen in geval van nood?',
    'It is important that we know who to contact in case of an emergency. Please provide below the details of a contact person we can call in case of an emergency.': 'Het is belangrijk dat we weten wie we moeten contacteren in geval van nood. Geef hieronder de gegevens op van een contactpersoon die we kunnen bellen in noodsituaties.',
    'Explanation': 'Toelichting',
    'Please provide the nessecary documents': 'Geef de benodigde documenten door',
    'Depending on your role we need some documents to help us with planning wishes. If you do not have the documents at hand, you can skip them and add them to your profile later.': 'Afhankelijk van uw rol in het platform hebben we documenten nodig die ons helpen met het plannen van de wensen. Mocht u de documenten niet bij de hand hebben kunt u deze overslaan en later aan uw profiel toevoegen.'
};
