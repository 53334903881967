<template>
    <NotificationPanel />
    <Sidebar
        v-model="sidebarOpen"
        :navigation-items="navigation"
    >
        <slot></slot>
    </Sidebar>
</template>

<script setup lang="ts">
import {
    faCalendar,
    faCalendarRange,
    faCode,
    faCoins,
    faFileInvoiceDollar,
    faFiles,
    faGift,
    faTruckMedical,
    faUsers,
} from '@fortawesome/pro-regular-svg-icons';
import useAuthStore from '~/stores/auth';
import { PermissionName, RoleName } from '~/graphql/graphql';
import env, { featureFlag } from '~/services/env';
import type { NavigationMenu } from '~/typings/types';

const auth = useAuthStore();
const sidebarOpen = ref(false);

const isDevelopment = ['local', 'test'].includes(env('VITE_SERVER_ENVIRONMENT'));

const navigation = computed<NavigationMenu>(() => [
    {
        name: 'Wishes',
        items: [
            {
                name: 'Wishes', route: '/wish', icon: faGift,
            },
            {
                name: 'Wish planning', route: '/wish-planning', icon: faCalendarRange, visible: auth.hasRole(RoleName.Admin, RoleName.Planner),
            },
            {
                name: 'Vehicle planning', route: '/vehicle-planning', icon: faCalendar, visible: auth.hasRole(RoleName.Admin, RoleName.Planner),
            },
            {
                name: 'Availability', route: '/availability', icon: faCalendar, visible: auth.hasRole(RoleName.Driver, RoleName.Nurse),
            },
        ],
    },
    ...(
        featureFlag('VITE_EXPENSES_ENABLED') ? [{
            name: 'Financial',
            items: [
                {
                    name: auth.hasRole(RoleName.Driver, RoleName.Nurse) ? 'Declaration' : 'Expenses',
                    route: '/expenses',
                    icon: faCoins,
                    visible: auth.hasPermission(PermissionName.ExpensesViewAll),
                },
            ],
        }] : []
    ),
    {
        name: 'Volunteers',
        items: [
            {
                name: 'Availability', route: '/volunteer-availability', icon: faCalendar, visible: auth.hasRole(RoleName.Admin, RoleName.Planner),
            },
        ],
    },
    {
        name: 'Management',
        items: [
            {
                name: 'Users', route: '/users', icon: faUsers, visible: auth.hasRole(RoleName.Admin, RoleName.Planner),
            },
            {
                name: 'Vehicles', route: '/vehicles', icon: faTruckMedical, visible: auth.hasRole(RoleName.Admin, RoleName.Planner),
            },
            {
                name: 'Files', route: '/documents', icon: faFiles, visible: auth.hasRole(RoleName.Admin, RoleName.Planner),
            },
            {
                name: 'Exact Online', route: '/exact', icon: faFileInvoiceDollar, visible: auth.hasPermission(PermissionName.ManageExact),
            },
        ],
    },
    ...(isDevelopment ? [{
        name: 'Development',
        items: [
            {
                name: 'Components', route: '/components', icon: faCode, visible: auth.hasRole(RoleName.Admin),
            },
        ],
    }] : []),
]);
</script>
