import type { Toast, ToastConfig, ToastType } from '~/typings/types';

export const toasts = ref(new Map<symbol, Toast>());

export function hasActiveToasts(): boolean {
    return toasts.value.size > 0;
}

export default function useToast(type: ToastType, config: ToastConfig, timer: number | null = 5000): symbol {
    const id = Symbol('toast-id');

    toasts.value.set(id, {
        id,
        type,
        config,
        timer,
    });

    return id;
}
