export default {
    'Activity log': 'Wens historie',
    'Read more': 'Lees meer',
    'Read less': 'Lees minder',

    activity: {
        type: {
            'App\\Models\\Mail': {
                created: 'Mail \'{subject}\' verstuurd'
            }
        },
        subjects: {
            'App\\Models\\Wish': 'wens',
            'App\\Models\\MedicalInformation': 'medische informatie',
            'App\\Models\\Address': 'adres',
            'App\\Models\\Note': 'Opmerking',
            'App\\Models\\Mail': 'mail',
        },
        events: {
            'created': 'aangemaakt',
            'updated': 'bijgewerkt',
            'deleted': 'verwijderd',
            'restored': 'hersteld',
        },
        by: '{entity} {event} | {entity} {event} door',
        noActivity: 'Geen activiteit gevonden',
    }
};
