import baseRules from '@vee-validate/rules';
import { configure, defineRule } from 'vee-validate';
import genericRules from '~/validation/rules/generic';
import ibanRules from '~/validation/rules/iban';
import bigRules from '~/validation/rules/big';
import type { App, Plugin } from 'vue';
import type { FieldValidationMetaInfo } from '@vee-validate/i18n';

export type ValidationRuleFunction<TValue = unknown, TParameters = unknown[] | Record<string, unknown> | any > =
    (value: TValue, params: TParameters, context: FieldValidationMetaInfo) => boolean | string | Promise<boolean | string>;

export const rules: Record<string, ValidationRuleFunction> = {
    ...baseRules,
    ...genericRules,
    ...ibanRules,
    ...bigRules,
};

const validation: Plugin = {
    install: <T>(app: App<T>): App<T> => {
        const translate = app.config.globalProperties.$t;

        Object.keys(rules).forEach(rule => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            defineRule(rule, rules[rule]);
        });

        configure({
            generateMessage: context => {
                const params: Record<string, any> = {};

                Object.values(context.rule?.params ?? []).forEach(
                    (parameter: any, index) => {
                        if (!parameter) return;
                        params[`${context.rule?.name}_${index}`] = parameter;
                    },
                );

                return translate(`validationErrors.${context.rule?.name}`, {
                    field: translate(context.field),
                    value: context.value,
                    ...params,
                });
            },
        });

        return app;
    },
};

export default validation;
