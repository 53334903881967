export default {
    Dashboard: 'Dashboard',
    Planning: 'Planning',
    Wishes: 'Wensen',
    Availability: 'Beschikbaarheid',
    Components: 'Components',
    'Your Profile': 'Ga naar je profiel',
    Settings: 'Instellingen',
    'Sign out': 'Uitloggen',
    Users: 'Gebruikers',
}
