<template>
    <div class="w-full overflow-hidden rounded-3xl bg-white shadow-card sm:w-auto">
        <div
            class="my-6 h-full"
            :class="{
                'px-4': space === 'sm',
                'px-6': space === 'md',
                'px-8': space === 'lg',
                'px-10': space === 'xl',
            }"
        >
            <slot></slot>
        </div>
    </div>
</template>

<script setup lang="ts">
interface Props {
    space?: 'sm'|'md'|'lg'|'xl';
}

const props = withDefaults(defineProps<Props>(), {
    space: 'md',
});

provide('cardSpace', props.space);
</script>
