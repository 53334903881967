import dayjs from 'dayjs';
import { isString } from '~/helpers/string';
import type { ValidationRuleFunction } from '~/validation';

const genericRules: Record<string, ValidationRuleFunction> = {
    contains_number: (value): boolean => isString(value) && /\d/.test(value),
    contains_uppercase: (value): boolean => isString(value) && /[A-Z]/.test(value),
    contains_lowercase: (value): boolean => isString(value) && /[a-z]/.test(value),
    contains_special_char: (value): boolean => isString(value) && /\W|_/.test(value),

    date_before_now: (value): boolean => !value || dayjs(value as string).isBefore(dayjs()),
    date_after_now: (value): boolean => !value || dayjs(value as string).isAfter(dayjs()),
    date_day_today_past: (value): boolean => !value || dayjs(value as string).subtract(1, 'day').isBefore(dayjs(), 'day'),
    date_day_today_future: (value): boolean => !value || dayjs(value as string).add(1, 'day').isAfter(dayjs(), 'day'),

    required_boolean: (value): boolean => typeof value === 'boolean' || value instanceof Boolean,

    // https://github.com/validatorjs/validator.js/blob/master/src/lib/isMobilePhone.js
    mobile_phone: (value): boolean => isString(value) && /^(((\+|00)?31\(0\))|((\+|00)?31)|0)6\d{8}$/.test(value),
    mileage: (value, [target]): boolean => Number(value) > target,
};

export default genericRules;
