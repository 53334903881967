import { defineStore } from 'pinia';

const useSidebar = defineStore('sidebar', () => {
    const collapsed = ref<boolean>(false);

    const toggle = (): boolean => {
        collapsed.value = !collapsed.value;

        return collapsed.value;
    };

    const open = (): void => {
        collapsed.value = false;
    };

    const close = (): void => {
        collapsed.value = true;
    };

    return {
        collapsed,
        toggle,
        open,
        close,
    };
}, {
    persist: {
        storage: {
            getItem: (key: string) => JSON.parse(localStorage.getItem(key) ?? '{}'),
            setItem: (key: string, value: string) => localStorage.setItem(key, JSON.stringify(value)),
        },
    },
});

export default useSidebar;
