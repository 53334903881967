import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import RelativeTime from 'dayjs/plugin/relativeTime';
import WeekOfYear from 'dayjs/plugin/weekOfYear';
import Weekday from 'dayjs/plugin/weekday';
import IsBetween from 'dayjs/plugin/isBetween';
import { createI18n } from 'vue-i18n';
import type { I18nOptions } from 'vue-i18n';
import type { Plugin, WritableComputedRef } from 'vue';

const SUPPORTED_LOCALES = {
    en: async () => import('dayjs/locale/en'),
    nl: async () => import('dayjs/locale/nl'),
} as const;

const rightToLeft = new Set(['ar', 'fa', 'he', 'ur']);

export type Locale = keyof typeof SUPPORTED_LOCALES;

const datetimeFormatSetup = {
    shortDate: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    },
    shortDateTime: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    },
    humanReadableDate: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    },
    humanReadableDateWithWeek: {
        year: 'numeric',
        month: 'long',
        weekday: 'long',
        day: 'numeric',
    },
    humanReadableTime: {
        hour: 'numeric',
        minute: 'numeric',
    },
    full: {
        year: 'numeric',
        month: 'long',
        weekday: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    },
    humanReadableDateLong: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
    },
    humanReadableDateTime: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    }
} as const

// const date = new Date();
// const str = Intl.DateTimeFormat('nl', {
//     timeStyle:
// })

export const datetimeFormats: I18nOptions<object, Locale>['datetimeFormats'] = {
    en: datetimeFormatSetup,
    nl: datetimeFormatSetup,
};

const numberFormatSetup = {
    decimal: {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    },
    currency: {
        style: 'currency',
        currency: 'EUR',

    }

} as const

const numberFormats: I18nOptions<object, Locale>['numberFormats'] = {
    en: numberFormatSetup,
    nl: numberFormatSetup,
};

export const currentLocale = ref<Locale>();

export async function setLocale(currentI18nLocale: WritableComputedRef<string>, locale: Locale): Promise<void> {
    if (currentLocale.value === locale) {
        return;
    }

    await SUPPORTED_LOCALES[locale]();
    currentI18nLocale.value = locale;
    currentLocale.value = locale;
    dayjs.locale(locale);
    document.documentElement.lang = locale;
    // eslint-disable-next-line unicorn/prevent-abbreviations
    document.documentElement.dir = rightToLeft.has(locale) ? 'rtl' : 'ltr';
}

export default async function createLocalizer<
    M extends Record<string, any>
>(locale: Locale, fallback: Locale, messageSchema: Partial<Record<Locale, M>>): Promise<Plugin> {
    const preferredLang = window.navigator.language || document.documentElement.lang;
    const locale_ = preferredLang in SUPPORTED_LOCALES ? preferredLang as Locale : locale;
    // eslint-disable-next-line unicorn/prevent-abbreviations
    document.documentElement.dir = rightToLeft.has(locale_) ? 'rtl' : 'ltr';
    await SUPPORTED_LOCALES[fallback]();
    await SUPPORTED_LOCALES[locale_]();
    dayjs.extend(LocalizedFormat);
    dayjs.extend(RelativeTime);
    dayjs.extend(WeekOfYear);
    dayjs.extend(Weekday);
    dayjs.extend(IsBetween);
    dayjs.locale(locale_);
    currentLocale.value = locale_;

    return createI18n({
        datetimeFormats,
        numberFormats,
        legacy: false,
        locale: locale_,
        fallbackLocale: fallback,
        warnHtmlMessage: false,
        formatFallbackMessages: true,
        messages: messageSchema,
        missingWarn: false,
        fallbackWarn: false,
    });
}
