export default {
    'Licence plate': 'Kenteken',
    'Model': 'Model',
    'Mileage': 'Kilometerstand',
    'Add vehicle': 'Voertuig toevoegen',
    'Delete vehicle': 'Voertuig verwijderen',
    'Restore vehicle': 'Voertuig herstellen',
    'Vehicle deleted': 'Voertuig verwijderd',
    'The vehicle has been deleted successfully.': 'Het voertuig is succesvol verwijderd.',
    'Are you sure you want to delete this vehicle?': 'Weet je zeker dat je dit voertuig wil verwijderen?',
    'Vehicle number': 'Voertuignummer',
    'Show deleted vehicles': 'Toon verwijderde voertuigen',
}
