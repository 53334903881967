import planningPopup from "./planning-popup";
import vehicleAvailability from "./vehicle-availability";
import expenseForm from "~/i18n/nl/components/expense-form";
import addressFrom from "~/i18n/nl/components/address-form";
import expenseRelinkForm from "~/i18n/nl/components/expense-relink-form";
import activity from "~/i18n/nl/components/activity";

const misc = {
    step: 'Stap {step}',
};

export default {
    ...planningPopup,
    ...vehicleAvailability,
    ...expenseForm,
    ...expenseRelinkForm,
    ...addressFrom,
    ...misc,
    ...activity,
};
