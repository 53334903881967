<template>
    <TransitionRoot
        as="template"
        :show="notifications.notificationPanelOpen"
    >
        <HeadlessDialog
            as="div"
            class="relative z-50"
            @close="notifications.closeNotificationPanel"
        >
            <TransitionChild
                as="template"
                enter="ease-in-out duration-500"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-500"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div
                    class="fixed inset-0 bg-gray-500/75 transition-opacity"
                    data-testid="notification-panel-backdrop"
                ></div>
            </TransitionChild>

            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                        <TransitionChild
                            as="template"
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from="translate-x-full"
                            enter-to="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leave-from="translate-x-0"
                            leave-to="translate-x-full"
                        >
                            <DialogPanel
                                class="pointer-events-auto w-screen max-w-md"
                                data-testid="notification-panel"
                            >
                                <div class="relative flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                    <div class="px-4 sm:px-6">
                                        <div class="flex items-start justify-between">
                                            <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
                                                {{ $t('Notifications') }}
                                            </DialogTitle>
                                            <div class="ml-3 flex h-7 items-center">
                                                <button
                                                    type="button"
                                                    class="rounded-md bg-white text-gray-400 hover:text-gray-500
                                                    focus:outline-none focus:ring-2 focus:ring-teal-500
                                                    focus:ring-offset-2"
                                                    data-testid="notification-panel-close-btn"
                                                    @click="notifications.closeNotificationPanel"
                                                >
                                                    <span class="sr-only">Close panel</span>
                                                    <XMarkIcon
                                                        class="h-6 w-6"
                                                        aria-hidden="true"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="relative mt-6 flex flex-1 flex-col gap-4 overflow-y-scroll px-4 sm:px-6">
                                        <TransitionGroup
                                            move-class="transition duration-300"
                                            enter-active-class="transition duration-300"
                                            leave-active-class="transition absolute duration-300"
                                            enter-from-class="opacity-0"
                                            leave-to-class="opacity-0"
                                        >
                                            <Notification
                                                v-for="notification in notifications.notifications"
                                                :key="notification.original.id"
                                                :notification="notification"
                                            />
                                        </TransitionGroup>
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </HeadlessDialog>
    </TransitionRoot>
</template>

<script lang="ts" setup>
import {
    Dialog as HeadlessDialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/20/solid';
import useNotifications from '~/stores/notifications';
import Notification from '~/components/notifications/Notification.vue';

const notifications = useNotifications();
</script>

<style scoped>

</style>
