export default {
    Wishes: 'Wensen',
    Planning: 'Planning',
    Availability: 'Beschikbaarheid',
    Financial: 'Financieel',
    Expenses: 'Uitgaven',
    Management: 'Beheer',
    Users: 'Gebruikers',
    Vehicles: 'Voertuigen',
    Documents: 'Documenten',
    Components: 'Componenten',
    Development: 'Development',
};
