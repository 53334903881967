
import authRoutes from '~/routes/auth';
import vehicleRoutes from './vehicle';
import documentRoutes from './document';
import userRoutes from './user';
import wishRoutes from './wish';
import accountRoutes from '~/routes/account';
import expensesRoutes from '~/routes/expenses';
import devRoutes from '~/routes/dev';
import useAuthStore from '~/stores/auth';
import { PermissionName, RoleName } from '~/graphql/graphql';
import { isDev } from '~/services/env';
import exactRoutes from '~/routes/exact';
import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'home',
        redirect: (): object => {
            const auth = useAuthStore();

            if (auth.hasRole(RoleName.Planner)) {
                return { name: 'wish-planning' };
            }

            return { name: 'wish.overview' };
        },
        meta: {
            middleware: {
                before: ['auth'],
            },
        },
    },
    {
        path: '/profile-wizard',
        name: 'profile-wizard',
        component: async () => import('../views/profile-wizard/Main.vue'),
        meta: {
            middleware: {
                before: ['auth'],
            },
        },
    },
    {
        path: '/availability',
        name: 'availability',
        component: async () => import('../views/Availability.vue'),
        meta: {
            middleware: {
                before: ['auth', 'can'],
            },
            can: [PermissionName.AvailabilityView],
        },
    },
    {
        path: '/wish-planning',
        name: 'wish-planning',
        component: async () => import('../views/WishPlanning.vue'),
        meta: {
            middleware: {
                before: ['auth', 'can'],
            },
            can: [PermissionName.PlanningViewAll],
        },
    },
    {
        path: '/vehicle-planning',
        name: 'vehicle-planning',
        component: async () => import('../views/VehiclePlanning.vue'),
        meta: {
            middleware: {
                before: ['auth', 'can'],
            },
            can: [PermissionName.PlanningViewAll],
        },
    },
    {
        path: '/volunteer-availability',
        name: 'volunteer-availability',
        component: async () => import('~/views/VolunteerAvailability.vue'),
    },
    ...authRoutes,
    ...vehicleRoutes,
    ...documentRoutes,
    ...userRoutes,
    ...wishRoutes,
    ...accountRoutes,
    ...expensesRoutes,
    ...exactRoutes,
];

if (isDev()) {
    routes.push(...devRoutes);
}

routes.push(
    {
        path: '/not-found',
        name: 'not-found',
        component: async () => import('../views/error/NotFound.vue'),
    },
    {
        path: '/unauthorized',
        name: 'unauthorized',
        component: async () => import('../views/error/Unauthorized.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: {
            name: 'not-found',
        },
    },
);

export default routes;
