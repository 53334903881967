import { PermissionName } from '~/graphql/graphql';
import type { RouteRecordRaw } from 'vue-router';

const vehicleRoutes: RouteRecordRaw[] = [
    {
        name: 'vehicle.overview',
        path: '/vehicles',
        component: async () => import('~/views/vehicle/Overview.vue'),
        meta: {
            middleware: {
                before: ['auth', 'can'],
            },
            can: [PermissionName.VehiclesViewAll],
        },
    },
    {
        name: 'vehicles.detail',
        path: '/vehicles/:id',
        component: async () => import('~/views/vehicle/Detail.vue'),
        meta: {
            middleware: {
                before: ['auth', 'can'],
            },
            can: [PermissionName.VehiclesViewAll],
        },
    },
];

export default vehicleRoutes;
