import useAuthStore from '~/stores/auth';
import { normalizeRoute } from '~/lib/Router';
import type { RouteLocationNormalized } from 'vue-router';
import type { Destination, Middleware, Next } from '~/typings/types';

export default class Guest implements Middleware {
    public async handle(to: RouteLocationNormalized, next: Next): Promise<Destination> {
        const auth = useAuthStore();

        if (auth.authenticated) {
            return normalizeRoute('home');
        }

        if (auth.hasPendingLogin) {
            return normalizeRoute('auth.2fa');
        }

        return next(to);
    }
}
