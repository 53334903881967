<template>
    <div
        class="relative flex flex-col gap-2 rounded bg-sky-500 p-4 text-white transition-opacity"
        :class="{ 'opacity-20 hover:opacity-50': notification.read }"
    >
        <div class="absolute right-2 top-2 flex items-center justify-center gap-2">
            <span class="text-xs">{{ $d(new Date(notification.original.created_at ?? 0), 'shortDateTime') }}</span>
            <button
                class="-m-2 p-2"
                type="button"
                @click="() => notifications.setDeleted(notification.uuid)"
            >
                <XMarkIcon class="h-4 w-4" />
            </button>
        </div>
        <span class="font-semibold">
            {{ $t(notification.header) }}
        </span>
        <p class="text-sm">
            {{ $t(notification.body) }}
        </p>
        <div class="flex gap-2">
            <button
                v-if="notification.read"
                class="rounded bg-sky-600 p-2 text-xs hover:bg-sky-400"
                @click="() => notifications.setUnRead(notification.uuid)"
            >
                {{ $t('Mark as unread') }}
            </button>
            <button
                v-else
                class="rounded bg-sky-600 p-2 text-xs hover:bg-sky-400"
                @click="() => notifications.setRead(notification.uuid)"
            >
                {{ $t('Mark as read') }}
            </button>
            <button
                v-if="notification.action"
                class="rounded bg-sky-600 p-2 text-xs hover:bg-sky-400"
                @click="(event) => notification.action?.handler(event)"
            >
                {{ $t(notification.action.label) }}
            </button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { XMarkIcon } from '@heroicons/vue/20/solid';
import useNotifications from '~/stores/notifications';
import type { Notification } from '~/stores/notifications';

interface Props {
    notification: Notification;
}

defineProps<Props>();
const notifications = useNotifications();
</script>

<style scoped>

</style>
