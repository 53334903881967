export default {
    'Wish information': `
    <h6>Hoe gaat een wensaanvraag in zijn werk?</h6>
    <p>
        Om een wens in te kunnen plannen hebben wij een ingevuld intakeformulier nodig.
        Dit formulier kan hieronder ingevuld worden.
        Zodra het intakeformulier binnen is dan nemen onze wensplanners contact op.
        Tijdens onze openingstijden wordt er vaak binnen een uur contact met de contactpersoon opgenomen,
        worden de mogelijkheden doorgesproken en de wens ingepland.
    </p>

    <h6>We hebben 3 soorten wensen</h6>
    <p>
        <strong>1. Spoed wensen</strong><br>
        Dit zijn wensen die op dezelfde dag dat ze binnenkomen nog uitgevoerd moeten worden.
        Over het algemeen kan vaak één van onze ambulances binnen 1 uur vertrekken naar de patiënt.
    </p>

    <p>
        <strong>2. Reguliere wensen</strong><br>
        Dit zijn alle overige wensen die binnenkomen en in Nederland plaatsvinden.
        Vaak kunnen deze wensen binnen 1 à 2 dagen na het indienen van het intakeformulier worden vervuld.
    </p>

    <p>
        <strong>3. Buitenlandse wensen</strong><br>
        We gaan ook naar het buitenland met wensen. Maximaal 3 à 4 dagen per wens inclusief de reisdagen.
    </p>

    <h6>Voor wie?</h6>
    <p>Voor iedereen die afhankelijk is van liggend vervoer met onze ambulances kunnen wij een wens vervullen.</p>

    <h6>Kosten?</h6>
    <p>Alle wensen worden gratis vervuld.<br> De kosten voor iedereen in de ambulance worden door de Stichting gedragen.</p>
    <p>In de ambulance kunnen in totaal 5 personen namelijk; de patiënt, 2 familieden / vrienden van de patiënt en 2 vrijwilligers van de Stichting(chauffeur en verpleegkundige).</p>

    <h6>Goed om te weten?</h6>
    <p>
    - Voor een wens plannen wij alleen een begintijd en geen eindtijd. Onze vrijwilligers staan de hele dag ter beschikking.<br>
    - Het is helaas niet toegestaan en mogelijk rolstoelen te vervoeren in onze ambulances.<br>
    - Vermeld als contactpersoon een telefoonnummer op het formulier waar we u kunnen bereiken tussen 8 en 17 uur.
    </p>

    <p>Heeft u nog vragen, dan kunt u ons ook bellen op <a class="font-bold" href="tel:0854444288">085 - 4444288</a></p>`,
    'Wish description': 'Beschrijf uw wens',
    'Preferred wish date': 'Voorkeursdatum voor uw wens',
    'The wish': 'De wens',
    'Who is the wish for?': 'Voor wie is de wens?',
    'We have succesfully received your request. Our wish planners will contact you as soon as possible. If you have any questions in the mean time, feel free to contact us via 085-4444288.': `
    Wij hebben je gegevens goed ontvangen. Onze wensplanners nemen zo spoedig mogelijk contact met je op.
    Mochten er in de tussentijd vragen hebben dan zijn wij telefonisch te bereiken op het telefoonnnummer 085-4444288.`,
    'Thank you for submitting your wish!': 'Bedankt voor het opsturen van je wens!',
    Request: 'Aanvraag',
    'Date and location': 'Datum en locatie',
    'Extra information': 'Aanvullende informatie',
    Declaration: 'Declaraties',
    'Elevator available?': 'Lift aanwezig?',
    'Is the destination address accessible for a stretcher?': 'Is het bestemmingsadres toegankelijk voor een brancard?',
    'Gender label': 'Geslacht',
    'Additional information': 'Aanvullende informatie',
    'How did you find our organization?': 'Hoe bent u bij ons terecht gekomen?',
    'Have you made a wish request before at our organization?': 'Heeft eerder een wensaanvraag ingediend bij Stichting Ambulance Wens?',
    'Do you have any active wish requests at another organization?': 'Heeft u op dit moment een actieve aanvraag ingediend bij een andere stichting of organisatie?',
    'To which organization has this wish request been submitted?': 'Bij welke organisatie is deze wensaanvraag ingediend?',
    'Is it possible to fulfill this wish with a passenger car or wheelchair taxi?': 'Is het mogelijk deze wens met een personenauto of rolstoeltaxi te vervullen?',
    'Body weight': 'Gewicht patiënt',
    'Medical condition and life expectancy': 'Medische situatie en levensverwachting',
    'Is the patient COVID positive?': 'Is de patiënt COVID positief?',
    'Is anyone at the location COVID positive?': 'Is er iemand op de locatie COVID positief?',
    'Any medical aids: (Feed tube, Ostomy, Oxygen, Catheter, etc)': 'Eventuele medische hulpmiddelen: (Sondevoeding, Stoma, Zuurstof, Catheter, enz.)',
    'Does the patient want to be resuscitated?': 'Wilt de patiënt gereanimeerd worden?',
    'Does the patient have a DNR (Do Not Resuscitate) statement?': 'Heeft de patiënt een DNR (Do Not Resuscitate) verklaring?',
    'How mobile is the patient?': 'Hoe mobiel is de patiënt?',
    'Mobility explained differently': 'Mobiliteit anders toegelicht',
    'Location covid positive': 'Is de persoon of het bestemmingsadres Covid positief?',
    'Archive wish': 'Wens archiveren',
    'Restore wish': 'Wens dearchiveren',
    'Change status': 'Status wijzigen',
    'Are you sure you want to archive this wish?': 'Weet je zeker dat je deze wens wilt archiveren?',
    'Are you sure you want to restore this wish from the archive?': 'Weet je zeker dat je deze wens wilt dearchiveren?',
    'Health insurer': 'Zorgverzekeraar',
    'Health insurance number': 'Zorgverzekeringsnummer',
    'Clinical picture': 'Ziektebeeld',
    'Wish created successfully': 'Wens succesvol aangemaakt',
    'You will now be redirected to the detail page of the wish.': 'Je wordt nu doorgestuurd naar de detailpagina van de wens.',
    'You did not pass the reCAPTCHA': 'U bent niet geslaagd voor de reCAPTCHA',
};
