<template>
    <RouterView v-slot="{ Component }">
        <Suspense>
            <template #default>
                <template v-if="authenticated && shouldUseAppLayout()">
                    <AppLayoutVue>
                        <component :is="Component" />
                    </AppLayoutVue>
                </template>
                <template v-else>
                    <component :is="Component" />
                </template>
            </template>
            <template #fallback>
                <template v-if="authenticated && shouldUseAppLayout()">
                    <AppLayoutVue>
                        <LoadingScreen />
                    </AppLayoutVue>
                </template>
                <template v-else>
                    <LoadingScreen />
                </template>
            </template>
        </Suspense>
    </RouterView>
    <ToastContainer />
</template>

<script setup lang="ts">
import useAuthStore from './stores/auth';
import AppLayoutVue from './views/AppLayout.vue';
import LoadingScreen from './views/LoadingScreen.vue';
import useNotifications from '~/stores/notifications';

const authStore = useAuthStore();
const route = useRoute();

const shouldUseAppLayout = (): boolean => !route.meta.customLayout;

const authenticated = computed(() => {
    return authStore.authenticated;
});

onMounted(() => {
    useNotifications();
});
</script>
