export default {
    Notifications: 'Notificaties',
    'Complete your account': 'Maak je account af',
    'Please fill out some details about you.': 'Vul alsjeblieft je account gegevens aan.',
    Continue: 'Ga verder',
    'New wish': 'Nieuwe wens',
    '{contact} has requested a new wish for {date}.': '{contact} heeft een nieuwe wens aangevraagd voor {date}.',
    'View the wish request': 'Bekijk de wensaanvraag',
    'Mark as read': 'Markeer als gelezen',
    'Mark as unread': 'Markeer als niet gelezen',
};
