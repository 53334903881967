<template>
    <nav
        class="flex-1"
        :class="{ 'px-6': !collapsed }"
        data-testid="main-nav"
    >
        <template
            v-for="item in navItems"
            :key="item.name"
        >
            <NavigationGroup
                v-if="Object.hasOwn(item, 'items')"
                :item="item as NavigationMenuGroup"
                :collapsed="collapsed"
            />
            <NavigationItem
                v-else
                :item="item as NavigationMenuItem"
                :collapsed="collapsed"
            />
        </template>
    </nav>
</template>

<script lang="ts" setup>
import type { NavigationMenu, NavigationMenuItem, NavigationMenuGroup } from '~/typings/types';

interface Props {
    items: NavigationMenu;
    collapsed?: boolean;
}

const props = defineProps<Props>();

const navItems = computed(() => props.items.filter(item => item.visible === undefined || item.visible));
</script>
