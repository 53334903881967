export default {
    'Export selection': 'Selectie exporteren',
    'Select type': 'Kies type',
    'Select status': 'Kies status',
    'No volunteers have been linked. Click here to link a volunteer.': 'Er zijn geen vrijwilligers gekoppeld. Klik hier om de vrijwilligers te koppelen.',
    '{expense_type} deleted': '{expense_type} verwijderd',
    'Edit {expense_type}': '{expense_type} wijzigen',
    'Delete {expense_type}': '{expense_type} verwijderen',
    'Are you sure you want to delete this {expense_type}?': 'Weet je zeker dat je deze {expense_type} wilt verwijderen?',
    'The {expense_type} has been successfully deleted.': 'De {expense_type} is succesvol verwijderd.',
    'The {expense_type} has been successfully saved.': 'De {expense_type} is succesvol opgeslagen.',
    'The {expense_type} has been successfully created.': 'De {expense_type} is succesvol aangemaakt.',
    'From address': 'Van adres',
    'To address': 'Naar adres',
    'Addressbook': 'Adresboek',
    'Show all addresses': 'Toon alle adressen',
    'New address': 'Nieuw adres',
    'Your address was not found using the entered zip code. Please enter an address manually.': 'Uw adres werd niet gevonden aan de hand van de ingevulde postcode. Voer handmatig een adres in.',
    'Based on {travel_allowance_rate} cents per km': 'Op basis van {travel_allowance_rate} cent per km',
    'There is no address selected.': 'Er is geen adres geselecteerd.',
    'Roundtrip': 'Retour',
    'The selected expenses are exported to the SEPA file. Do you want to change the status from "Approved" to "Processed"?': 'De geselecteerde uitgaven worden naar het SEPA-bestand geëxporteerd. Wil je de status van “Goedgekeurd” naar “Verwerkt” wijzigen?',
    'All expenses are exported to the SEPA file. Do you want to change the status from "Approved" to "Processed"?': 'Alle uitgaven worden naar het SEPA-bestand geëxporteerd. Wil je de status van “Goedgekeurd” naar “Verwerkt” wijzigen?',
    'Export and update status': 'Exporteren en status wijzigen',
    'Relink {expense_type}': '{expense_type} koppeling wijzigen',
    'Wish link': 'Wens koppeling',
    'Linked to wish': 'Gekoppeld aan wens',
    'Not linked to wish': 'Niet gekoppeld aan wens',
    'Select link': 'Kies koppeling',
}

