const config = {
    locale: 'nl',
    fallbackLocale: 'en',
    amountOfAmbulanceSlots: 2,
    maxAmountOfDays: 4,
    volunteersPerWish: 2,
    subscriptionRemountInterval: 300_000,
    sentryDsn: 'https://cad553ce34b2f4620808a154e450cccd@o1076030.ingest.sentry.io/4506303998918656',
} as const;

export default config;
