export default {
    'Vehicle information': 'Voertuig informatie',
    Availability: 'Beschikbaarheid',
    'Not adjustable': 'Niet aanpasbaar',
    'Available': 'Beschikbaar',
    'Not available': 'Niet beschikbaar',
    'Error while updating vehicle': 'Fout tijdens het updaten van het voertuig',
    'Due to an unforeseen error, the vehicle could not be updated.': 'Door een onbekende fout is het voertuig niet bijgewerkt.',
    'Vehicle updated': 'Voertuig bijgewerkt',
    'The vehicle has been updated successfully.': 'Het voertuig is met succes bijgewerkt.',
    'Export mileages': 'Kilometerregistratie exporteren',
    'Export': 'Exporteren',
}
