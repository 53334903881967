export default {
    'Update vehicle availability': 'Beschikbaarheid voertuig aanpassen',
    'Unavailable on': 'Niet beschikbaar op',
    'Available on': 'Beschikbaar op',
    'A wish has already been scheduled for this day.': 'Voor deze dag is er al een wens ingepland.',
    'A concept wish has already been scheduled for this day.': 'Voor deze dag is er al een wens in concept ingepland.',
    'A reservation has already been scheduled for this day.': 'Voor deze dag is er al een reservering ingepland.',
    'Adjust planning': 'Pas planning aan',
    'Reason for not available...': 'Reden voor niet beschikbaar...',
    'The reason for availability has been adjusted': 'De reden van de beschikbaarheid is aangepast',
}
