import type { RouteRecordRaw } from 'vue-router';

const accountRoutes: RouteRecordRaw[] = [
    {
        name: 'account.profile',
        path: '/account/profile',
        component: async () => import('~/views/account/Profile.vue'),
        meta: {
            middleware: {
                before: ['auth', 'can'],
            },
        },
    },
];

export default accountRoutes;
