import { useQueryPromise } from '~/services/graphql';
import { useBigCheckQuery } from '~/graphql/graphql';
import type { ValidationRuleFunction } from '~/validation';

const bigCheck = async (value: unknown): Promise<boolean> => {
    if (!value) return true;

    if (typeof value !== 'string') return false;

    try {
        const response = await useQueryPromise(useBigCheckQuery({ big: value }));

        if (!response.data.bigCheck?.registrations) return false;

        return !!response.data.bigCheck.registrations?.filter(item => (item?.valid ?? false)).length;
    } catch {
        return false;
    }
};

const bigRules: Record<string, ValidationRuleFunction> = {
    big: bigCheck,
};

export default bigRules;
