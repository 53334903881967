export default {
    'Sign in to your account': 'Login op je account',
    'Email address': 'E-mailadres',
    Password: 'Wachtwoord',
    'Current password': 'Huidig wachtwoord',
    'Remember me': 'Onthoud mij',
    'Forgot your password?': 'Wachtwoord vergeten?',
    'Sign in': 'Inloggen',
    'Sign out': 'Uitloggen',
    'Show profile': 'Toon profiel',
    'Show wish': 'Toon wens',
    'Show planning': 'Toon planning',
    Cancel: 'Annuleer',
    Confirm: 'Bevestig',
    'Preview images': 'Voorbeeld afbeeldingen',
    'Preview image': 'Voorbeeld afbeelding',
    'Password confirmation': 'Wachtwoord bevestiging',
    'Chose a new password': 'Kies een nieuw wachtwoord',
    'You\'ve got mail!': 'Je hebt mail!',
    'Enter the email address associated with your account below. You will then receive an email within a few minutes with a link to create a new password.':
        'Vul hieronder het e-mailadres in dat is gekoppeld aan je account. Je ontvangt vervolgens binnen enkele minuten een e-mail met daarin een link om een '
        + 'nieuw wachtwoord aan te maken.',
    Send: 'Versturen',
    Resend: 'Stuur opnieuw',
    'Change password': 'Wachtwoord aanpassen',
    'Reset password': 'Wachtwoord opnieuw instellen',
    'Enter the new password below': 'Vul hieronder het nieuwe wachtwoord in',
    'A password reset link has been sent to your email address. If you haven\'t received it, please check your spam/junk folders.':
        'Er is een wachtwoord herstel link verzonden naar je e-mailadres. Als je deze niet hebt ontvangen, controleer dan je spam/ongewenst mappen.',
    'Password contains at least {number} characters': 'Wachtwoord bevat minimaal {number} tekens',
    'Password contains a number': 'Wachtwoord bevat een nummer',
    'Password contains a uppercase letter': 'Wachtwoord bevat een hoofdletter',
    'Password contains a lowercase letter': 'Wachtwoord bevat een kleine letter',
    'Password contains a special character': 'Wachtwoord bevat een speciaal teken',
    'Something went wrong': 'Er is iets misgegaan',
    'Unfortunately an unidentified error has occurred.': 'Helaas is er ergens in de applicatie een onbekende fout opgetreden.',
    'We could not send you an email. Please try again later.': 'We konden je geen e-mail sturen. Probeer het later opnieuw.',
    'Invalid reset link': 'Ongeldige herstellink',
    'Request a new recovery link via "Forgot your password?"': 'Vraag een nieuwe herstellink aan via "Wachtwoord vergeten?"',
    'Password updated': 'Wachtwoord aangepast',
    'You can login with your new password': 'Je kan nu inloggen met je nieuwe wachtwoord',
    'Password updated successfully': 'Wachtwoord succesvol aangepast',
    'Two-factor authentication': 'Two-factor authenticatie',
    'You have been sent an email containing a {digits}-digit code. Enter that code below to login. Please note that the code is valid up to {expiration} minutes after sending. Please note that the code is valid up to {expiration} minutes after sending.':
        'Er is een e-mail naar je verstuurd met een {digits}-cijferige code. Voer die code hieronder in. De code is geldig tot {expiration} minuten na het versturen.',
    'You have been sent a text message (to the phone number ending with {number}) containing a {digits}-digit code. Enter that code below to login. Please note that the code is valid up to {expiration} minutes after sending.':
        'Er is zojuist een SMS verzonden naar het nummer dat eindigt op ...{number}, met daarin een {digits}-cijferige code. Vul de ontvangen code hieronder in. Deze code blijft geldig tot {expiration} minuten na het verzenden.',
    'This verification code is incorrect.': 'Deze verificatiecode is onjuist.',
    'This verification code has expired': 'Deze verificatiecode is verlopen.',
    'Please note that it may take up to a minute for the code to be send to you.': 'Houd er rekening mee dat het enkele minuten kan duren voordat je de code ontvangt.',
    'Go back': 'Ga terug',
    'I have not received any code. Send me a new one.': 'Ik heb de code niet ontvangen. Stuur deze opnieuw.',
    'Need help while logging in? Please ask for help via': 'Problemen met inloggen? Neemt contact op via',
    'or by phone via': 'of telefonisch via',
}
