export default {
    'Something went wrong': 'Er is iets fout gegaan',
    'Could not be saved': 'Kon niet opgeslagen worden',
    'Please try again later.': 'Probeer het alsjeblieft later opnieuw.',
    'Incorrect email or password': 'Ongeldig e-mailadres of wachtwoord',
    'Check that you have entered the correct email address and password.': 'Controleer of je het juiste e-mailadres en wachtwoord hebt ingevoerd.',
    'You may not change the status from final to unscheduled.': "Het is alleen toegestaan om conceptwensen terug te zetten naar 'Planbare wensen'",
    'You may not change the status from confirmed to unscheduled.': "Het is alleen toegestaan om conceptwensen terug te zetten naar 'Planbare wensen'",
    "The input.arrival at must be a date after or equal to input.departure at.": "De aankomsttijd moet na de vertrektijd liggen",
}
