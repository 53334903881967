import Pusher from 'pusher-js';

import { ApolloLink, Observable } from '@apollo/client/core';
import { lowerCaseFirstLetter } from '~/helpers/string';
import useAuthStore from '~/stores/auth';
import env from './env';
import type { FetchResult, NextLink, Operation } from '@apollo/client/core';

interface SubscriptionObserver<T = FetchResult> {
    closed: boolean;
    next: (value: T) => void;
    error: (errorValue: any) => void;
    complete: () => void;

    /*
     * This taps in to the inner workings of "zen-observable" but it's not officially supported, so use with care!
     */
    _subscription: {
        _cleanup?: () => void;
    };
}

interface SubscriptionChannel {
    channel: string;
    observer: SubscriptionObserver;
}

class PusherLink extends ApolloLink {
    private readonly subscriptions = new Map<string, SubscriptionChannel>();
    private readonly pusher: Pusher;

    public constructor() {
        super();

        this.pusher = new Pusher(env('VITE_PUSHER_KEY'), {
            wsHost: env('VITE_PUSHER_HOST'),
            cluster: env('VITE_PUSHER_CLUSTER'),
            disableStats: true,
            forceTLS: false,
            enabledTransports: ['ws', 'wss'],
            activityTimeout: 5000,

            channelAuthorization: {
                transport: 'ajax',
                endpoint: `${env('VITE_GRAPHQL_URL')}/subscriptions/auth`,
                headersProvider: (): Record<string, any> => {
                    const token = useAuthStore().accessToken;

                    return {
                        Accept: 'application/json',
                        Authorization: token ? `Bearer ${token}` : undefined,
                    };
                },
            },
        });
    }

    public request(operation: Operation, forward?: NextLink): Observable<FetchResult> | null {
        if (!forward) return null;

        return new Observable(observer => {
            forward(operation).subscribe({
                next: data => {
                    const subscriptionChannel = PusherLink.getChannel(data, operation);

                    if (subscriptionChannel) {
                        // eslint-disable-next-line no-underscore-dangle
                        (observer as SubscriptionObserver)._subscription._cleanup = (): void => {
                            this.leaveSubscription(subscriptionChannel, observer as SubscriptionObserver);
                        };

                        this.createSubscription(subscriptionChannel, observer as SubscriptionObserver);
                    } else {
                        observer.next(data);
                        observer.complete();
                    }
                },
            });
        });
    }

    private static getChannel(data: FetchResult, operation: Operation): string | null {
        const subscriptions = data.extensions?.lighthouse_subscriptions;

        if (subscriptions) {
            switch (subscriptions?.version ?? 2) {
                case 1:
                    return subscriptions.channels[lowerCaseFirstLetter(operation.operationName)] ?? null;
                case 2:
                    return subscriptions.channel ?? null;
                default:
                    return null;
            }
        }

        return null;
    }

    private createSubscription(channel: string, observer: SubscriptionObserver): void {
        if (!this.subscriptions.has(channel)) {
            this.subscriptions.set(channel, {
                channel,
                observer,
            });
        }

        this.pusher
            .subscribe(channel)
            .bind('lighthouse-subscription', (payload: any) => {
                if (!payload.more) {
                    this.leaveSubscription(channel, observer);
                    observer.complete();
                }

                if (payload.result) {
                    observer.next(payload.result);
                }
            });
    }

    public leaveSubscription(channel: string, observer: SubscriptionObserver): void {
        if (!this.subscriptions.has(channel)) return;

        this.pusher.unsubscribe(channel);
        observer.complete();

        this.subscriptions.delete(channel);
    }
}

export default PusherLink;
