import auth from "./auth";
import availability from "./availability";
import checklists from './checklists';
import documents from "./documents";
import planning from "./planning";
import vehicleOverview from "./vehicle-overview";
import wishRequest from "./wish-request";
import wishDetail from "./wish-detail";
import profile from "./profile";
import userOverview from "./user-overview";
import userDetail from "./user-detail";
import vehicleDetail from "./vehicle-detail";
import wishConfirm from "./wish-confirm";
import expenses from "~/i18n/nl/views/expenses";
import exact from "~/i18n/nl/views/exact";

export default {
    ...planning,
    ...availability,
    ...checklists,
    ...documents,
    ...vehicleOverview,
    ...vehicleDetail,
    ...wishRequest,
    ...wishDetail,
    ...auth,
    ...profile,
    ...userOverview,
    ...userDetail,
    ...wishConfirm,
    ...expenses,
    ...exact,
};
