export default {
    'Exact Online': 'Exact Online',
    'API status': 'API status',
    'Exact config': 'Exact configuratie',
    'Authentication session expired': 'Authenticatie sessie verlopen',
    'Exact enabled': 'Exact ingeschakeld',
    'Division': 'Administratie',
    'Journals': 'Dagboeken',
    'General ledger accounts': 'Grootboekrekeningen',
    'Select a division': 'Selecteer een administratie',
    'Select a journal': 'Selecteer een dagboek',
    'Select a general ledger account': 'Selecteer een grootboekrekening',
};
