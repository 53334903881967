import type { RouteLocationNormalized } from 'vue-router';
import type { Destination, Middleware, Next } from '~/typings/types';

export default class EnsureEmailIsVerified implements Middleware {
    public async handle(to: RouteLocationNormalized, next: Next): Promise<Destination> {
        // if (user doesn't have a verified email) {
        //     redirect
        // }

        return next(to);
    }
}
