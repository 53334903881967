export default {
    'Availability confirmed!': 'Beschikbaarheid bevestigd!',
    'Go to wish overview': 'Ga naar wens overzicht',
    'wish_confirmation_text': `
        <p>
            Hierbij bevestigen we dat jouw beschikbaarheid succesvol is bevestigd. Hierdoor weten we zeker dat de wens kan worden vervuld.
        </p>

        <p>
            Als je nog vragen hebt, neem dan gerust contact met ons op via <a class="font-bold" href="tel:0854444288">085-4444288</a> of stuur een e-mail naar
            <a class="font-bold" href="mailto:info{'@'}ambulancewens.nl">info{'@'}ambulancewens.nl</a>.
        </p>

        <p>
            Bedankt voor je tijd en vrijwillige inzet, we waarderen het echt enorm!
        </p>
    `,
}
