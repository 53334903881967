export default {
    'Volunteer(s) not available': 'Vrijwilliger(s) niet beschikbaar',
    'Volunteer(s) successfully made available': 'Vrijwilliger(s) succesvol beschikbaar gemaakt',
    'A day could not be made available for one of the volunteers.': 'Het is niet gelukt om een dag een van de vrijwillegers op beschikbaar te zetten.',
    'The selected volunteer(s) are now made available for the wish date.': 'De geselecteerde vrijwilliger(s) is/zijn succesvol op beschikbaar gezet voor de ingevoerde wens datum. ',
    'Make available': 'Maak beschikbaar',
    'Wish requires two volunteers.': 'Er zijn twee vrijwilligers nodig om een wens definitief in te kunnen plannen.',
    'Volunteer has already been planned for wish date.': 'Een van de geselecteerde vrijwilligers is al voor een andere wens ingepland.',
    'The selected volunteer(s) is/are not available for the required date(s).': 'De geselecteerde vrijwilliger(s) is/zijn niet beschikbaar op de ingevoerde wens datum.',
    'Wish requires both a driver and a nurse.': 'Om een wens te kunnen inplannen moet er zowel een chauffeur als een verpleegkundige geselecteerd zijn.',
    'Start date is not a valid Y-m-d date string.': 'De ingevoerde wensdatum is niet geldig.',
    'Start or date date could not be parsed and is likely an invalid date string.': 'De ingevoerde wensdatum is niet geldig.',
    'End date is not a valid Y-m-d date string.': 'De ingevoerde einddatum is niet geldig.',
    'Failed to retrieve wish notes.': 'De aantekeningen voor deze wens kunnen momenteel niet geladen worden.',
    'Due to an unknown error your note could not be saved.': 'Wegens een onbekende fout kon je aantekening niet opgeslagen worden.',
    'Due to an unknown error your mileage could not be saved.': 'Wegens een onbekende fout kon je kilometerregistratie niet opgeslagen worden.',
    'Your note has been saved.': 'De opmerking is met success opgeslagen',
    'Your profile has been saved.': 'Je profiel is met success opgeslagen',
    'Activation email successfully sent': 'Activatie e-mail succesvol verzonden',
    'There was an error while trying to save the data. Please make sure all fields have been filled in properly.': 'Het is helaas niet gelukt de data op te slaan. Controleer of je alle velden goed hebt ingevuld.',
    'Error while saving data': 'Er ging iets mis tijdens het opslaan',
    'The data has been saved successfully.': 'De data is met success opgeslagen.',
    'User restored': 'Gebruiker teruggezet',
    'User archived': 'Gebruiker gearchiveerd',
    'The user has been archived.': 'De gebruiker is gearchiveerd.',
    'The user has been restored.': 'De gebruiker is teruggezet.',
    'Wish restored': 'Wens teruggezet',
    'Wish archived': 'Wens gearchiveerd',
    'The wish has been archived.': 'De wens is gearchiveerd.',
    'Failed to archive the wish.': 'Er is iets fout gegaan tijdens het archiveren van de wens.',
    'The wish has been restored.': 'De wens is teruggezet.',
    'Failed to restore the wish.': 'Er is iets fout gegaan tijdens het terugzetten van de wens.',
    'Error while saving vehicle': 'Er is iets fout gegaan tijdens het opslaan van het voertuig.',
    'Due to an unknown error the status of the wish could not be updated.': 'Door een onverwachte fout kon de status van de wens niet aangepast worden.',
    'Vehicle added': 'Voertuig toegevoegd',
    'Vehicle updated': 'Voertuig gewijzigd',
    'Vehicle restored': 'Voertuig hersteld',
    'The vehicle is succesfully added.': 'Het voertuig is succesvol toegevoegd.',
    'The vehicle is succesfully updated.': 'Het voertuig is succesvol gewijzigd.',
    'The vehicle has been restored successfully.': 'Het verwijderen van dit voertuig is succesvol ongedaan gemaakt.',
    'Success': 'Succes',
    'Code succesfully sent': 'Code succesvol verzonden',
    'You have been sent a new verification code. Please notice it may take a couple of seconds for you to receive our message.':
        'We hebben je een nieuwe code gestuurd. Hou er rekening mee dat het een paar tellen kan duren voordat je deze ontvangen hebt.',
    'Failed to resend the code': 'Code kon niet verzonden worden.',
    'Something went wrong. We could not resend your code at this time.': 'Er is iets fout gegaan bij het opnieuw versturen van de code.',
    'There was an error updating the availability of this vehicle.': 'Er ging iets verkeerd. De beschikbaarheid van dit voertuig kon niet opgeslagen worden.',
    'Rate limit exceeded': 'Te veel pogingen gedaan',
    'Please try again in a minute.': 'Probeer het over een minuut nog eens.',
    'Saving dayplanning failed': 'Opslaan van dagplanning is mislukt',
    'Reservation date conflicts with another planned reservation.': 'Reserveringsdatum conflicteert met een geplande reservering.',
    'Reservation date conflicts with a planned wish.':'Reserveringsdatum conflicteert met een geplande wens.',
    'Wish date conflicts with a planned reservation.': 'Wensdatum conflicteert met een geplande reservering.',
    'The wish over multiple days is conflicting with another wish.': 'De meerdaagse wens heeft overlap met een ingeplande wens.',
    'Performed action is not allowed': 'Uitgevoerde actie is niet toegestaan',
    'Failed to export expenses': 'Exporteren van uitgaven mislukt',
    'SEPA exported successfully': 'Het SEPA-bestand is succesvol geëxporteerd',
    'The selected expenses have been successfully exported to a SEPA file.': 'De geselecteerde uitgaven zijn succesvol geëxporteerd naar een SEPA-bestand.',
    'All expenses have been successfully exported to a SEPA file.': 'Alle uitgaven zijn succesvol geëxporteerd naar een SEPA-bestand.',
    'Failed to export expenses to a SEPA file': 'Uitgaven exporteren naar een SEPA-bestand mislukt',
    'The selected expenses are not compatible to be exported to a SEPA file.': 'De geselecteerde uitgaven voldoen niet aan de criteria om geëxporteerd te worden.',
    'All expenses are not compatible to be exported to a SEPA file.': 'Niet alle uitgaven voldoen aan de criteria om geëxporteerd te worden.',
    'No exportable expenses found within current selection.': 'Er zijn geen exporteerbare uitgaven gevonden binnen het huidige bereik.',
    'A wish can only have one patient': 'Een wens kan maar één patiënt hebben',
    'A wish must have at least one contact person': 'Een wens moet minimaal één contactpersoon hebben',
    'A wish can only have one doctor': 'Een wens kan maar één arts hebben',
    'A wish must have at least one mailable contact person': 'Een wens moet minimaal één contactpersoon hebben die updates wil ontvangen',
    'Expense not found': 'Uitgave niet gevonden',
    'Expense is already approved or payed': 'Uitgave is al goedgekeurd of betaald',
    'Expense is not a card expense': 'Uitgave is geen kaartuitgave',
    'Wish not found': 'Wens niet gevonden',
    'The link to the wish has been saved.': 'De koppeling naar de wens is opgeslagen.',
};
