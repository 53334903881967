import datetime from "./datetime";
import layout from "./layout";
import misc from "./misc";
import navigation from "./navigation";
import errors from "./errors";

export default {
    ...datetime,
    ...navigation,
    ...layout,
    ...misc,
    ...errors,
};
