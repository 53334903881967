import { PermissionName } from '~/graphql/graphql';
import type { RouteRecordRaw } from 'vue-router';

const userRoutes: RouteRecordRaw[] = [
    {
        name: 'user.overview',
        path: '/users',
        component: async () => import('~/views/user/Overview.vue'),
        meta: {
            middleware: {
                before: ['auth', 'can'],
            },
            can: [PermissionName.UsersViewAll],
        },
    },
    {
        name: 'user.detail',
        path: '/user/:id',
        component: async () => import('~/views/user/Detail/index.vue'),
        meta: {
            middleware: {
                before: ['auth', 'can'],
            },
            can: [PermissionName.UsersView],
        },
    },
];

export default userRoutes;
