export default {
    'Photo or scan of the receipt': 'Foto of scan van de bon',
    'Advance by a volunteer?': 'Voorgeschoten door een vrijwilliger?',
    'Cannot attach expenses to a wish that is not scheduled or executed': 'Kan geen kosten koppelen aan een wens die niet is gepland of uitgevoerd',
    'Cannot update expenses on a wish that is not scheduled or executed': 'Kan geen kosten wijzigen op een wens die niet is gepland of uitgevoerd',
    'The user is not assigned to this wish.': 'De gebruiker is niet aan deze wens toegewezen.',
    'Expense not found or expense already approved.': 'Uitgaven niet gevonden of al goedgekeurd.',
    'Error while saving expense': 'Fout bij het opslaan van onkosten',
    'The expense have been saved.': 'De uitgaven zijn opgeslagen.'
}
