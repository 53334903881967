export default {
    'Could not attach the file.': 'We konden het bestand niet koppelen.',
    'Could not detach the file.': 'We konden het bestand niet ontkoppelen.',
    'Could not delete the file.': 'We konden het bestand niet verwijderen.',
    'File attached': 'Bestand gekoppeld',
    'File detached': 'Bestand ontkoppeld',
    'File uploaded': 'Bestand geüpload',
    'File deleted': 'Bestand verwijderd',
    'The file has been uploaded successfully.': 'Het bestand is succesvol geüpload.',
    'The file has been attached successfully.': 'Het bestand is succesvol gekoppeld.',
    'The file has been detached successfully.': 'Het bestand is succesvol ontkoppeld.',
    'The file has been deleted successfully.': 'Het bestand is succesvol verwijderd.',
    'Choose file': 'Bestand selecteren',
    'Choose files': 'Bestanden selecteren',
    'Choose from files': 'Kies uit bestanden',
    'Upload file': 'Bestand uploaden',
    'No file selected': 'Geen bestand geselecteerd.',
    'Delete file': 'Bestand verwijderen',
    'Are you sure you want to delete this file?': 'Weet je zeker dat je dit bestand wil verwijderen?',
}
