import { Country } from '~/graphql/graphql';

export default {
    [`Country-${Country.Af}`]: 'Afghanistan',
    [`Country-${Country.Ax}`]: 'Åland',
    [`Country-${Country.Al}`]: 'Albanië',
    [`Country-${Country.Dz}`]: 'Algerije',
    [`Country-${Country.As}`]: 'Amerikaans-Samoa',
    [`Country-${Country.Ad}`]: 'Andorra',
    [`Country-${Country.Ao}`]: 'Angola',
    [`Country-${Country.Ai}`]: 'Anguilla',
    [`Country-${Country.Aq}`]: 'Antarctica',
    [`Country-${Country.Ag}`]: 'Antigua en Barbuda',
    [`Country-${Country.Ar}`]: 'Argentinië',
    [`Country-${Country.Am}`]: 'Armenië',
    [`Country-${Country.Aw}`]: 'Aruba',
    [`Country-${Country.Au}`]: 'Australië',
    [`Country-${Country.At}`]: 'Oostenrijk',
    [`Country-${Country.Az}`]: 'Azerbeidzjan',
    [`Country-${Country.Bs}`]: 'Bahama’s',
    [`Country-${Country.Bh}`]: 'Bahrein',
    [`Country-${Country.Bd}`]: 'Bangladesh',
    [`Country-${Country.Bb}`]: 'Barbados',
    [`Country-${Country.By}`]: 'Belarus',
    [`Country-${Country.Be}`]: 'België',
    [`Country-${Country.Bz}`]: 'Belize',
    [`Country-${Country.Bj}`]: 'Benin',
    [`Country-${Country.Bm}`]: 'Bermuda',
    [`Country-${Country.Bt}`]: 'Bhutan',
    [`Country-${Country.Bo}`]: 'Bolivia',
    [`Country-${Country.Ba}`]: 'Bosnië en Herzegovina',
    [`Country-${Country.Bw}`]: 'Botswana',
    [`Country-${Country.Bv}`]: 'Bouveteiland',
    [`Country-${Country.Br}`]: 'Brazilië',
    [`Country-${Country.Io}`]: 'Brits Indische Oceaanterritorium',
    [`Country-${Country.Vg}`]: 'Britse Maagdeneilanden',
    [`Country-${Country.Bn}`]: 'Brunei',
    [`Country-${Country.Bg}`]: 'Bulgarije',
    [`Country-${Country.Bf}`]: 'Burkina Faso',
    [`Country-${Country.Bi}`]: 'Burundi',
    [`Country-${Country.Kh}`]: 'Cambodja',
    [`Country-${Country.Cm}`]: 'Kameroen',
    [`Country-${Country.Ca}`]: 'Canada',
    [`Country-${Country.Cv}`]: 'Kaapverdië',
    [`Country-${Country.Bq}`]: 'Caribisch Nederland',
    [`Country-${Country.Ky}`]: 'Kaaimaneilanden',
    [`Country-${Country.Cf}`]: 'Centraal-Afrikaanse Republiek',
    [`Country-${Country.Td}`]: 'Tsjaad',
    [`Country-${Country.Cl}`]: 'Chili',
    [`Country-${Country.Cn}`]: 'China',
    [`Country-${Country.Cx}`]: 'Christmaseiland',
    [`Country-${Country.Cc}`]: 'Cocoseilanden',
    [`Country-${Country.Co}`]: 'Colombia',
    [`Country-${Country.Km}`]: 'Comoren',
    [`Country-${Country.Cg}`]: 'Congo-Brazzaville',
    [`Country-${Country.Cd}`]: 'Congo-Kinshasa',
    [`Country-${Country.Ck}`]: 'Cookeilanden',
    [`Country-${Country.Cr}`]: 'Costa Rica',
    [`Country-${Country.Ci}`]: 'Ivoorkust',
    [`Country-${Country.Hr}`]: 'Kroatië',
    [`Country-${Country.Cu}`]: 'Cuba',
    [`Country-${Country.Cw}`]: 'Curaçao',
    [`Country-${Country.Cy}`]: 'Cyprus',
    [`Country-${Country.Cz}`]: 'Tsjechië',
    [`Country-${Country.Dk}`]: 'Denemarken',
    [`Country-${Country.Dj}`]: 'Djibouti',
    [`Country-${Country.Dm}`]: 'Dominica',
    [`Country-${Country.Do}`]: 'Dominicaanse Republiek',
    [`Country-${Country.Ec}`]: 'Ecuador',
    [`Country-${Country.Eg}`]: 'Egypte',
    [`Country-${Country.Sv}`]: 'El Salvador',
    [`Country-${Country.Gq}`]: 'Equatoriaal-Guinea',
    [`Country-${Country.Er}`]: 'Eritrea',
    [`Country-${Country.Ee}`]: 'Estland',
    [`Country-${Country.Sz}`]: 'eSwatini',
    [`Country-${Country.Et}`]: 'Ethiopië',
    [`Country-${Country.Fk}`]: 'Falklandeilanden',
    [`Country-${Country.Fo}`]: 'Faeröer',
    [`Country-${Country.Fj}`]: 'Fiji',
    [`Country-${Country.Fi}`]: 'Finland',
    [`Country-${Country.Fr}`]: 'Frankrijk',
    [`Country-${Country.Gf}`]: 'Frans-Guyana',
    [`Country-${Country.Pf}`]: 'Frans-Polynesië',
    [`Country-${Country.Tf}`]: 'Franse Gebieden in de zuidelijke Indische Oceaan',
    [`Country-${Country.Ga}`]: 'Gabon',
    [`Country-${Country.Gm}`]: 'Gambia',
    [`Country-${Country.Ge}`]: 'Georgië',
    [`Country-${Country.De}`]: 'Duitsland',
    [`Country-${Country.Gh}`]: 'Ghana',
    [`Country-${Country.Gi}`]: 'Gibraltar',
    [`Country-${Country.Gr}`]: 'Griekenland',
    [`Country-${Country.Gl}`]: 'Groenland',
    [`Country-${Country.Gd}`]: 'Grenada',
    [`Country-${Country.Gp}`]: 'Guadeloupe',
    [`Country-${Country.Gu}`]: 'Guam',
    [`Country-${Country.Gt}`]: 'Guatemala',
    [`Country-${Country.Gg}`]: 'Guernsey',
    [`Country-${Country.Gn}`]: 'Guinee',
    [`Country-${Country.Gw}`]: 'Guinee-Bissau',
    [`Country-${Country.Gy}`]: 'Guyana',
    [`Country-${Country.Ht}`]: 'Haïti',
    [`Country-${Country.Hm}`]: 'Heard en McDonaldeilanden',
    [`Country-${Country.Hn}`]: 'Honduras',
    [`Country-${Country.Hk}`]: 'Hongkong SAR van China',
    [`Country-${Country.Hu}`]: 'Hongarije',
    [`Country-${Country.Is}`]: 'IJsland',
    [`Country-${Country.In}`]: 'India',
    [`Country-${Country.Id}`]: 'Indonesië',
    [`Country-${Country.Ir}`]: 'Iran',
    [`Country-${Country.Iq}`]: 'Irak',
    [`Country-${Country.Ie}`]: 'Ierland',
    [`Country-${Country.Im}`]: 'Isle of Man',
    [`Country-${Country.Il}`]: 'Israël',
    [`Country-${Country.It}`]: 'Italië',
    [`Country-${Country.Jm}`]: 'Jamaica',
    [`Country-${Country.Jp}`]: 'Japan',
    [`Country-${Country.Je}`]: 'Jersey',
    [`Country-${Country.Jo}`]: 'Jordanië',
    [`Country-${Country.Kz}`]: 'Kazachstan',
    [`Country-${Country.Ke}`]: 'Kenia',
    [`Country-${Country.Ki}`]: 'Kiribati',
    [`Country-${Country.Kw}`]: 'Koeweit',
    [`Country-${Country.Kg}`]: 'Kirgizië',
    [`Country-${Country.La}`]: 'Laos',
    [`Country-${Country.Lv}`]: 'Letland',
    [`Country-${Country.Lb}`]: 'Libanon',
    [`Country-${Country.Ls}`]: 'Lesotho',
    [`Country-${Country.Lr}`]: 'Liberia',
    [`Country-${Country.Ly}`]: 'Libië',
    [`Country-${Country.Li}`]: 'Liechtenstein',
    [`Country-${Country.Lt}`]: 'Litouwen',
    [`Country-${Country.Lu}`]: 'Luxemburg',
    [`Country-${Country.Mo}`]: 'Macau SAR van China',
    [`Country-${Country.Mg}`]: 'Madagaskar',
    [`Country-${Country.Mw}`]: 'Malawi',
    [`Country-${Country.My}`]: 'Maleisië',
    [`Country-${Country.Mv}`]: 'Maldiven',
    [`Country-${Country.Ml}`]: 'Mali',
    [`Country-${Country.Mt}`]: 'Malta',
    [`Country-${Country.Mh}`]: 'Marshalleilanden',
    [`Country-${Country.Mq}`]: 'Martinique',
    [`Country-${Country.Mr}`]: 'Mauritanië',
    [`Country-${Country.Mu}`]: 'Mauritius',
    [`Country-${Country.Yt}`]: 'Mayotte',
    [`Country-${Country.Mx}`]: 'Mexico',
    [`Country-${Country.Fm}`]: 'Micronesia',
    [`Country-${Country.Md}`]: 'Moldavië',
    [`Country-${Country.Mc}`]: 'Monaco',
    [`Country-${Country.Mn}`]: 'Mongolië',
    [`Country-${Country.Me}`]: 'Montenegro',
    [`Country-${Country.Ms}`]: 'Montserrat',
    [`Country-${Country.Ma}`]: 'Marokko',
    [`Country-${Country.Mz}`]: 'Mozambique',
    [`Country-${Country.Mm}`]: 'Myanmar (Birma)',
    [`Country-${Country.Na}`]: 'Namibië',
    [`Country-${Country.Nr}`]: 'Nauru',
    [`Country-${Country.Np}`]: 'Nepal',
    [`Country-${Country.Nl}`]: 'Nederland',
    [`Country-${Country.Nc}`]: 'Nieuw-Caledonië',
    [`Country-${Country.Nz}`]: 'Nieuw-Zeeland',
    [`Country-${Country.Ni}`]: 'Nicaragua',
    [`Country-${Country.Ne}`]: 'Niger',
    [`Country-${Country.Ng}`]: 'Nigeria',
    [`Country-${Country.Nu}`]: 'Niue',
    [`Country-${Country.Nf}`]: 'Norfolk',
    [`Country-${Country.Kp}`]: 'Noord-Korea',
    [`Country-${Country.Mk}`]: 'Noord-Macedonië',
    [`Country-${Country.Mp}`]: 'Noordelijke Marianen',
    [`Country-${Country.No}`]: 'Noorwegen',
    [`Country-${Country.Om}`]: 'Oman',
    [`Country-${Country.Pk}`]: 'Pakistan',
    [`Country-${Country.Pw}`]: 'Palau',
    [`Country-${Country.Ps}`]: 'Palestijnse gebieden',
    [`Country-${Country.Pa}`]: 'Panama',
    [`Country-${Country.Pg}`]: 'Papoea-Nieuw-Guinea',
    [`Country-${Country.Py}`]: 'Paraguay',
    [`Country-${Country.Pe}`]: 'Peru',
    [`Country-${Country.Ph}`]: 'Filipijnen',
    [`Country-${Country.Pn}`]: 'Pitcairneilanden',
    [`Country-${Country.Pl}`]: 'Polen',
    [`Country-${Country.Pt}`]: 'Portugal',
    [`Country-${Country.Pr}`]: 'Puerto Rico',
    [`Country-${Country.Qa}`]: 'Qatar',
    [`Country-${Country.Re}`]: 'Réunion',
    [`Country-${Country.Ro}`]: 'Roemenië',
    [`Country-${Country.Ru}`]: 'Rusland',
    [`Country-${Country.Rw}`]: 'Rwanda',
    [`Country-${Country.Ws}`]: 'Samoa',
    [`Country-${Country.Sm}`]: 'San Marino',
    [`Country-${Country.St}`]: 'Sao Tomé en Principe',
    [`Country-${Country.Sa}`]: 'Saoedi-Arabië',
    [`Country-${Country.Sn}`]: 'Senegal',
    [`Country-${Country.Rs}`]: 'Servië',
    [`Country-${Country.Sc}`]: 'Seychellen',
    [`Country-${Country.Sl}`]: 'Sierra Leone',
    [`Country-${Country.Sg}`]: 'Singapore',
    [`Country-${Country.Sx}`]: 'Sint-Maarten',
    [`Country-${Country.Sk}`]: 'Slowakije',
    [`Country-${Country.Si}`]: 'Slovenië',
    [`Country-${Country.Sb}`]: 'Salomonseilanden',
    [`Country-${Country.So}`]: 'Somalië',
    [`Country-${Country.Za}`]: 'Zuid-Afrika',
    [`Country-${Country.Gs}`]: 'Zuid-Georgia en Zuidelijke Sandwicheilanden',
    [`Country-${Country.Kr}`]: 'Zuid-Korea',
    [`Country-${Country.Ss}`]: 'Zuid-Soedan',
    [`Country-${Country.Es}`]: 'Spanje',
    [`Country-${Country.Lk}`]: 'Sri Lanka',
    [`Country-${Country.Bl}`]: 'Saint-Barthélemy',
    [`Country-${Country.Sh}`]: 'Sint-Helena',
    [`Country-${Country.Kn}`]: 'Saint Kitts en Nevis',
    [`Country-${Country.Lc}`]: 'Saint Lucia',
    [`Country-${Country.Mf}`]: 'Saint-Martin',
    [`Country-${Country.Pm}`]: 'Saint-Pierre en Miquelon',
    [`Country-${Country.Vc}`]: 'Saint Vincent en de Grenadines',
    [`Country-${Country.Sd}`]: 'Soedan',
    [`Country-${Country.Sr}`]: 'Suriname',
    [`Country-${Country.Sj}`]: 'Spitsbergen en Jan Mayen',
    [`Country-${Country.Se}`]: 'Zweden',
    [`Country-${Country.Ch}`]: 'Zwitserland',
    [`Country-${Country.Sy}`]: 'Syrië',
    [`Country-${Country.Tw}`]: 'Taiwan',
    [`Country-${Country.Tj}`]: 'Tadzjikistan',
    [`Country-${Country.Tz}`]: 'Tanzania',
    [`Country-${Country.Th}`]: 'Thailand',
    [`Country-${Country.Tl}`]: 'Oost-Timor',
    [`Country-${Country.Tg}`]: 'Togo',
    [`Country-${Country.Tk}`]: 'Tokelau',
    [`Country-${Country.To}`]: 'Tonga',
    [`Country-${Country.Tt}`]: 'Trinidad en Tobago',
    [`Country-${Country.Tn}`]: 'Tunesië',
    [`Country-${Country.Tr}`]: 'Turkije',
    [`Country-${Country.Tm}`]: 'Turkmenistan',
    [`Country-${Country.Tc}`]: 'Turks- en Caicoseilanden',
    [`Country-${Country.Tv}`]: 'Tuvalu',
    [`Country-${Country.Um}`]: 'Kleine afgelegen eilanden van de Verenigde Staten',
    [`Country-${Country.Vi}`]: 'Amerikaanse Maagdeneilanden',
    [`Country-${Country.Ug}`]: 'Oeganda',
    [`Country-${Country.Ua}`]: 'Oekraïne',
    [`Country-${Country.Ae}`]: 'Verenigde Arabische Emiraten',
    [`Country-${Country.Gb}`]: 'Verenigd Koninkrijk',
    [`Country-${Country.Us}`]: 'Verenigde Staten',
    [`Country-${Country.Uy}`]: 'Uruguay',
    [`Country-${Country.Uz}`]: 'Oezbekistan',
    [`Country-${Country.Vu}`]: 'Vanuatu',
    [`Country-${Country.Va}`]: 'Vaticaanstad',
    [`Country-${Country.Ve}`]: 'Venezuela',
    [`Country-${Country.Vn}`]: 'Vietnam',
    [`Country-${Country.Wf}`]: 'Wallis en Futuna',
    [`Country-${Country.Eh}`]: 'Westelijke Sahara',
    [`Country-${Country.Ye}`]: 'Jemen',
    [`Country-${Country.Zm}`]: 'Zambia',
    [`Country-${Country.Zw}`]: 'Zimbabwe',
}
