export default {
    Monday: 'Maandag',
    Tuesday: 'Dinsdag',
    Wednesday: 'Woensdag',
    Thursday: 'Donderdag',
    Friday: 'Vrijdag',
    Saturday: 'Zaterdag',
    Sunday: 'Zondag',
    January: 'Januari',
    February: 'Februari',
    March: 'Maart',
    April: 'April',
    May: 'Mei',
    June: 'Juni',
    July: 'Juli',
    August: 'Augustus',
    September: 'September',
    October: 'Oktober',
    November: 'November',
    December: 'December',
    Today: 'Vandaag',
    Month: 'Maand',
    Week: 'Week',
    Day: 'Dag',
}
