export default {
    'Submit availability': 'Beschikbaarheid doorgeven',
    Today: 'Vandaag',
    'Jump to date': 'Spring naar datum',
    'available driver': 'beschikbare chauffeur',
    'available drivers': 'beschikbare chauffeurs',
    'available nurse': 'beschikbare verpleegkundige',
    'available nurses': 'beschikbare verpleegkundigen',
    Planning: 'Planning',
    'Previous month': 'Vorige maand',
    'Next month': 'Volgende maand',
    'Non editable': 'Niet aanpasbaar',
    'Wish planned': 'Wens ingepland',
    'Wish in concept': 'Wens is in concept',
    'Not available': 'Niet beschikbaar',
    'Available': 'Beschikbaar',
    'Busy saving': 'Bezig met opslaan',
    'Contact us by phone at {phone} to adjust your availability.': 'Neem telefonisch contact op via {phone} om je beschikbaar aan te passen.',
}
