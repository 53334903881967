import type { RouteRecordRaw } from 'vue-router';

const routes: Readonly<RouteRecordRaw[]> = [
    {
        path: '/components',
        name: 'components',
        component: async () => import('../../views/Components.vue'),
        meta: {
            middleware: {
                before: ['auth'],
            },
        },
    },
];

export default routes;
