<template>
    <div
        v-if="navItems.length > 0"
        class="mb-6 flex flex-col overflow-visible text-white last:mb-0"
        :class="{ 'items-center': collapsed }"
        data-testid="nav-group"
    >
        <h4
            class="mb-2 text-gray-300 transition-all"
            :class="{ 'text-2xs': collapsed }"
        >
            {{ $t(props.item.name) }}
        </h4>
        <NavigationItem
            v-for="navItem in navItems"
            :key="navItem.name"
            :item="navItem"
            :collapsed="collapsed"
        />
    </div>
</template>

<script lang="ts" setup>
import type { NavigationMenuGroup } from '~/typings/types';

interface Props {
    item: NavigationMenuGroup;
    collapsed?: boolean;
}

const props = defineProps<Props>();

const navItems = computed(() => props.item.items.filter(item => item.visible === undefined || item.visible));
</script>

<style scoped>

</style>
