export default {
    Profile: 'Profiel',
    'Profile information': 'Profiel informatie',
    'My files': 'Mijn bestanden',
    'Account details completed successfully': 'Accountgegevens succesvol aangevuld',
    'Your account details have been successfully completed and updated.': 'Je accountgegevens zijn succesvol aangevuld en bijgewerkt.',
    'Declaration of conduct (DOG)': 'Verklaring omtrent gedrag (VOG)',
    'Drivers license': 'Rijbewijs',
    'Degree': 'Diploma',
    'You have uploaded a file': 'U heeft een document geüpload',
    'View file': 'Document bekijken',
    'You haven\'t uploaded a file yet.': 'U heeft nog geen document geüpload.',
    'An error occurred while uploading the file. The file can be max 3MB in size.': 'Er is een fout opgetreden bij het uploaden van het document. Het document mag maximaal 3MB groot zijn.',
}
