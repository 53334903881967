<template>
    <span
        class="inline-flex  items-center justify-center rounded-full"
        :class="[background, text, customClass ?? 'h-8 w-8']"
    >
        <span class="text-sm font-medium leading-none">{{ initials(name) }}</span>
    </span>
</template>

<script setup lang="ts">
import type { CssClasses } from '~/typings/types';

type Props = {
    name: string;
    background?: string;
    text?: string;
    customClass?: CssClasses;
}

withDefaults(defineProps<Props>(), {
    background: 'bg-gray-500',
    text: 'text-white',
    customClass: undefined,
});

function initials(name: string): string {
    if (!name.trim()) {
        return 'U';
    }

    const fragments = name.split(' ');

    if (fragments.length === 1) {
        return fragments[0][0];
    }

    return fragments[0][0] + fragments[fragments.length - 1][0];
}
</script>
