<template>
    <li
        class="relative min-w-[20rem] max-w-[30rem] rounded-md p-4"
        :class="[themes[toast.type].background]"
        data-testid="toast"
        @mouseenter="clearTimer"
        @mouseleave="startTimer"
    >
        <div class="flex">
            <div class="shrink-0">
                <component
                    :is="themes[toast.type].icon"
                    class="h-5 w-5"
                    :class="themes[toast.type].iconColor"
                    aria-hidden="true"
                />
            </div>
            <div class="ml-3">
                <h3
                    class="text-sm font-medium"
                    :class="[themes[toast.type].header]"
                >
                    {{ $t(toast.config.header) }}
                </h3>
                <div
                    class="mt-2 text-sm"
                    :class="[themes[toast.type].body]"
                >
                    <p>{{ $t(toastBody) }}</p>
                </div>
                <div class="mt-4">
                    <div class="-mx-2 -my-1.5 flex">
                        <button
                            v-if="toast.config.action"
                            type="button"
                            class="rounded-md px-2 py-1.5 text-sm font-medium
                                focus:outline-none focus:ring-2 focus:ring-offset-2"
                            :class="[themes[toast.type].button]"
                            data-test-action
                            @click="runAction"
                        >
                            {{ $t(toast.config.action.label) }}
                        </button>
                        <button
                            type="button"
                            class="rounded-md px-2 py-1.5 text-sm font-medium
                                focus:outline-none focus:ring-2 focus:ring-offset-2"
                            :class="[themes[toast.type].button, {'ml-3': toast.config.action}]"
                            data-test-dismiss
                            @click="dismiss"
                        >
                            {{ $t('Dismiss') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="toast.timer"
            class="absolute bottom-0 left-0 h-1"
            :class="[themes[toast.type].timer]"
            :style="{
                width: `${100 - ((run / toast.timer) * 100)}%`
            }"
        >
        </div>
    </li>
</template>

<script setup lang="ts">
import {
    CheckCircleIcon,
    ExclamationTriangleIcon,
    InformationCircleIcon,
    XCircleIcon,
} from '@heroicons/vue/20/solid';
import { toasts } from '~/stores/toast';
import { isString } from '~/helpers/string';
import type { Component } from 'vue';
import type { Toast, ToastType } from '~/typings/types';

interface Props {
    toast: Toast;
}

type ToastTheme = {
    background: string;
    header: string;
    body: string;
    button: string;
    icon: Component;
    iconColor: string;
    timer: string;
}

const props = defineProps<Props>();

const run = ref(0);
let tracker: ReturnType<typeof setInterval> | null;
let timer: ReturnType<typeof setTimeout> | null;

const themes: Record<ToastType, ToastTheme> = {
    error: {
        background: 'bg-red-50',
        header: 'text-red-800',
        body: 'text-red-700',
        button: 'bg-red-50 text-red-800 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50',
        icon: XCircleIcon,
        iconColor: 'text-red-400',
        timer: 'bg-red-400',
    },
    info: {
        background: 'bg-teal-50',
        header: 'text-teal-800',
        body: 'text-teal-700',
        button: 'bg-teal-50 text-teal-800 hover:bg-teal-100 focus:ring-teal-600 focus:ring-offset-teal-50',
        icon: InformationCircleIcon,
        iconColor: 'text-teal-400',
        timer: 'bg-teal-400',
    },
    success: {
        background: 'bg-green-50',
        header: 'text-green-800',
        body: 'text-green-700',
        button: 'bg-green-50 text-green-800 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50',
        icon: CheckCircleIcon,
        iconColor: 'text-green-400',
        timer: 'bg-green-400',
    },
    warning: {
        background: 'bg-yellow-50',
        header: 'text-yellow-800',
        body: 'text-yellow-700',
        button: 'bg-yellow-50 text-yellow-800 hover:bg-yellow-100 focus:ring-yellow-600 focus:ring-offset-yellow-50',
        icon: ExclamationTriangleIcon,
        iconColor: 'text-yellow-400',
        timer: 'bg-yellow-400',
    },
};

const dismiss = (): void => {
    toasts.value.delete(props.toast.id);
};

const clearTracker = (): void => {
    if (tracker) {
        clearInterval(tracker);
        tracker = null;
    }
};

const startTimer = (): void => {
    if (!props.toast.timer) {
        return;
    }

    tracker = setInterval(() => {
        run.value += 10;
    }, 10);

    timer = setTimeout(() => {
        clearTracker();
        dismiss();
    }, props.toast.timer - run.value);
};

const clearTimer = (): void => {
    if (!props.toast.timer) {
        return;
    }

    if (timer) {
        clearTimeout(timer);
        timer = null;
    }

    clearTracker();
};

const runAction = (event: MouseEvent): void => {
    props.toast.config.action?.handler(event, props.toast.id);

    if (props.toast.config.action?.close) {
        dismiss();
    }
};

const toastBody = computed(() => (isString(props.toast.config.body) ? props.toast.config.body : props.toast.config.body.join(' ')));

onMounted(() => {
    startTimer();
});

onUnmounted(() => {
    clearTracker();
});
</script>
