<template>
    <Card
        :title="$t('One moment please')"
        :description="$t('We need a moment to fetch some data from the server')"
        class="max-w-2xl"
    >
        <Loader
            width="2.5rem"
            height="2.5rem"
        />
    </Card>
</template>

<script setup lang="ts">

</script>
