import { PermissionName } from '~/graphql/graphql';
import type { RouteRecordRaw } from 'vue-router';

const expensesRoutes: RouteRecordRaw[] = [
    {
        name: 'expenses.overview',
        path: '/expenses',
        component: async () => import('~/views/expenses/Overview.vue'),
        meta: {
            middleware: {
                before: ['auth', 'can'],
            },
            can: [PermissionName.ExpensesViewAll],
        },
    },
];

export default expensesRoutes;
